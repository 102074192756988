body {
  overflow-y: scroll;

  &.user-authenticated {
    background: #e5f5fc;

    footer .logo-nrz-badge-background {
      fill: #e5f5fc;
    }
  }
}

.form-pdf-view {
   header,
   footer,
   .signature-clear-button {
     display: none;
   }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.wizard-loaded {
  position: relative;
  width: 100%;

  .wizard-appear {
    position: relative;
  }

  .wizard-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  .wizard-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease;
  }

  .wizard-exit {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transform: translateX(0%);
  }

  .wizard-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 300ms ease;
  }

  &.wizard-reverse {
    .wizard-enter {
      transform: translateX(-100%);
    }

    .wizard-enter-active {
      transform: translateX(0%);
    }

    .wizard-exit-active {
      transform: translateX(100%);
    }
  }
}

